import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

export function Footer() {
  const { palette } = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '50px', backgroundColor: palette.secondary.main, color: palette.primary.light, width: '100vw',
      }}
      textAlign="center"
    >
      <Typography sx={{ textTransform: 'uppercase', fontSize: '10pt', fontWeight: 'bold' }}>
        Letícia Sassati Vieira de Souza | CNPJ: 45.479.894/0001-82 | Copyright
        {' '}
        {new Date().getFullYear()}
        {' '}
        | Todos os Direitos Reservados
      </Typography>
    </Box>
  );
}
